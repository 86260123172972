

export default{
    name: 'NotCertify',
    created() {
        
    },

    methods: {
        back(){
            this.$router.push({name: 'Console'})
        }
        
    },
    data() {
        return {
            usertype: _global.userInfo.usertype,
            userInfo: _global.userInfo,
            title:'< 返回'
        }
    },
}